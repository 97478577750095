
<template>
  <el-empty description="页面未找到">
    <el-button @click="back" class="back">点我返回</el-button>
  </el-empty>
</template>


<script>
export default {
  name: "",
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang='scss' scoped>
.el-empty {
  height: 100%;
  width: 100%;

  .back {
    font-size: 15px;
    color: gray;
  }
}
</style>