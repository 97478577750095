<template>
  <div class="login">
    <div class="left">
      <div class="header">
        <div class="header-left">
          <img :src="require('@/assets/router/logo@2x.png')" alt />
          <div>{{ $t('global.title') }}</div>
        </div>
        <div class="header-right">
          <el-popover placement="bottom" width="100" trigger="click" v-model="visible">
            <div class="popover_box" v-for="item in options" :key="item.langType">
              <div @click="chooseLang(item)">{{ item.langText }}</div>
            </div>

            <div class="currentLang" slot="reference">
              <img src="./assets/lang.png" alt="">
              <div>{{ currentLang }}</div><i class="el-icon-caret-bottom"></i>
            </div>

          </el-popover>

        </div>
      </div>

      <!-- <div class="tips">{{tips}}</div> -->
      <Invite v-if="curStep == 'code'" @next="gotoNext" />
      <Info v-if="curStep == 'info'" @back="back" @submit="submit" :account="submitForm.account" />
    </div>
    <img class="right" :src="bgUrl" alt />
  </div>
</template>

<script>
import { loginpwd, register, getLoginUser, checkUserAuditStatus, refreshToken } from "@/api/api/auth";
import { applyOrg, applyJoin } from "@/api/api/sysUser";
import Cookie from "js-cookie";
export default {
  data() {
    return {
      options: [
        {
          langType: "zh-CN",
          langText: "中文简体",
        },
        {
          langType: "en-US",
          langText: "English",
        },
        {
          langType: "ru-RU",
          langText: "Русскийязык ",
        },
      ],
      visible: false,
      lang: Cookie.get("lang") || "en-US",

      logoUrl: require("./assets/logo@2x.png"),
      bgUrl: require("./assets/bg@2x.png"),
      curStep: "code",

      submitForm: {
        account: "",
        realName: "",
        password: "",
      },
      userinfo: null,
      tips: "",
    };
  },
  computed: {
    currentLang() {
      let langText = null
      this.options.forEach(r => {
        if (r.langType === this.$lang) {
          langText = r.langText
        }
      })
      return langText
    }
  },

  methods: {
    chooseLang(option) {
      Cookie.set("lang", option.langType);
      location.reload();
      this.visible = false;
    },
    getuid() {
      let url = location.href;
      let queryStr = url.split("?")[1];
      let queryArr = queryStr.split("&")[1];
      let uuid = queryArr.split("=")[1];
      localStorage.setItem("uuid", uuid);
    },

    gotoNext(payload) {
      this.curStep = "info";
      this.submitForm.account = payload.account;
    },

    back() {
      this.curStep = "code";
    },

    submit(payload) {
      this.submitForm.realName = payload.name;
      this.submitForm.password = payload.password;
      //已注册
      // if (payload.ifreg) {
      //   this.login();
      // }
      // // //未注册
      // else {
      this.regAndlogin();
      // }
    },

    //注册
    regAndlogin() {
      let obj = {
        password: this.submitForm.password,
        name: this.submitForm.realName,
      };
      let regData = this.$lang === 'zh-CN' ? {
        phone: this.submitForm.account,
        ...obj
      } : {
        email: this.submitForm.account,
        ...obj
      };
      register(regData).then(async () => {
        let { data: data1 } = await getLoginUser();
        let { data } = await applyJoin({ inviteCode: localStorage.getItem("uuid"), userId: data1.id });
        // if (data) {
        this.getAuditStatus()
        // }







        // let ifAudit = false;
        //申请进入组织,data为null表示需要审核，data有信息代表无需审核
        // let { data } = await applyOrg({ orgid: localStorage.getItem("uid") });
        // if (!data) ifAudit = true;
        // //登录
        // this.getUserInfo(ifAudit);
      });
    },

    //登录
    login() {
      let loginData = {
        account: this.submitForm.account,
        password: this.submitForm.password,
      };
      loginpwd(loginData).then(async () => {

        // new
        let { data: data1 } = await getLoginUser();
        this.$store.commit("SET_USERINFO", data1);
        localStorage.setItem("userinfo", JSON.stringify(data1));
        let { data } = await applyJoin({ inviteCode: localStorage.getItem("uuid"), userId: data1.id });
        console.log('========', data)
        // if (data) {
        this.getAuditStatus()
        // let { data: data2 } = await checkUserAuditStatus()
        // if (data2) {
        //   console.log('用户审核状态查询=====', res.data)
        // }
        // }





        // let ifAudit = false;
        //申请进入组织,data为null表示需要审核，data有信息代表无需审核
        // let { data } = await applyOrg({ orgid: localStorage.getItem("uuid") });
        // if (!data) ifAudit = true;
        //获取用户信息
        //  this.getUserInfo(ifAudit)
      });
    },


    getAuditStatus() {
      checkUserAuditStatus().then((res) => {
        console.log('用户审核状态查询=====', res.data)
        // if (res.data) {
        if (res.data === 2) {
          // 游离拒绝审核
          this.$store.commit("SET_ADMINORUSER", "block");
          this.$store.commit("ADD_ROUTES", "block");
        } else if (res.data === 0) {
          // 游离但待审核的用户
          // this.$store.commit("SET_ADMINORUSER", "audit");
          // this.$store.commit("ADD_ROUTES", { info: "audit" });
          this.$store.commit("SET_ADMINORUSER", "audit");
          this.$store.commit("ADD_ROUTES", { info: "audit", jump: "jump" });
        } else if (res.data === 1 || res.data === null) {
          // 刷新token
          if (res.data === null) {
            console.log('==== 刷新令牌==')
            refreshToken().then((res) => {
              this.getUserinfo();
            });
          }
          setTimeout(() => {
            // 审核通过
            this.$store.commit("SET_ADMINORUSER", "user");
            this.$store.commit("ADD_ROUTES", { info: "user", jump: "jump" });
          },800)



        }
        // }
        // localStorage.removeItem("uuid")
      })
    },
    async getUserinfo() {
      await getLoginUser().then((res) => {
        let { data: result } = res;
        this.$store.commit("SET_USERINFO", result);
        localStorage.setItem("userinfo", JSON.stringify(result));
      });
    },
    //用户信息获取
    // async getUserInfo() {
    //   getLoginUser().then((res) => {
    //     let { data: result } = res;
    //     this.$store.commit("SET_USERINFO", result);
    //     localStorage.setItem("userinfo", JSON.stringify(result));
    //     // this.userinfo = res.data
    //     return res.data
    //游离但待审核的用户
    // if (result.tenantId == -1 && ifAudit) {
    //   this.$store.commit("SET_ADMINORUSER", "audit");
    //   this.$store.commit("ADD_ROUTES", { info: "audit", jump: "jump" });
    // }
    // //游离用户
    // else if (result.tenantId == -1) {
    //   this.$store.commit("SET_ADMINORUSER", "free");
    //   this.$store.commit("ADD_ROUTES", "free");
    // }
    // //被block的用户
    // else if (result.status == 2) {
    //   this.$store.commit("SET_ADMINORUSER", "block");
    //   this.$store.commit("ADD_ROUTES", "block");
    // }
    // //管理员
    // else if (result.userType == 1) {
    //   this.$store.commit("SET_ADMINORUSER", "admin");
    //   this.$store.commit("ADD_ROUTES", "admin");
    // }
    // //普通用户--被邀请只会成为普通用户
    // else if (result.userType == 0) {
    //   this.$store.commit("SET_ADMINORUSER", "user");
    //   this.$store.commit("ADD_ROUTES", { info: "user", jump: "jump" });
    // }

    // localStorage.removeItem("uuid");
    //   });
    // },
  },

  created() {
    // let userinfo = localStorage.getItem('userinfo')
    // if(userinfo && userinfo.tenantId != '-1') {
    //   this.$message.error('您已加入组织')
    //   this.tips = '*提示：您已加入组织,申请加入其它组织将会失败'
    // }
    this.getuid();
  },

  components: {
    Invite: () => import("./components/invite.vue"),
    Info: () => import("./components/info.vue"),
  },
};
</script>

<style lang="scss" scoped>
.popover_box {
  div {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;

    cursor: pointer;

    &:hover {
      background-color: rgba(41, 140, 255, 0.1);
    }
  }
}

body {
  min-width: 100vw;
}

a {
  text-decoration: none;
  color: #298cff;
}

.login {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .left {
    width: 100%;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-left {
        padding: 0 20px;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: left;
        font-size: 18px;
        font-weight: 700;
        height: 70px;

        img {
          height: 48px;
          width: 48px;
          margin-right: 15px;
        }
      }

      .header-right {
        .currentLang {
          display: flex;
          align-items: center;

          div {
            color: #666666;
            margin: 0 4px;
            font-size: 14px;
            font-weight: 700;
          }

          i {
            color: #666666;
            margin-left: -2px;
            margin-right: 4px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .right {
    display: block;
    height: 100%;
    width: auto;
  }
}

// .tips {
//   color:red;
//   font-size:0.95rem;
//   font-weight:600;
//   margin-left:20%;
//   transform:translateY(200%)
// }
</style>
