<template>
  <div class="login">
    <div class="left">
      <div class="header">
        <div class="header-left">
          <img :src="require('@/assets/router/logo@2x.png')" alt />
          <div>{{ $t('global.title') }}</div>
        </div>
        <div class="header-right">
          <el-popover placement="bottom" width="100" trigger="click" v-model="visible">
            <div class="popover_box" v-for="item in options" :key="item.langType">
              <div @click="chooseLang(item)">{{ item.langText }}</div>
            </div>

            <div class="currentLang" slot="reference">
              <img src="./assets/lang.png" alt="">
              <div>{{ currentLang }}</div><i class="el-icon-caret-bottom"></i>
            </div>

          </el-popover>

        </div>
      </div>

      <!-- 验证码登录 -------------------------------------------------------------------------------------------------------->
      <div class="body" v-if="loginType == 'code'" :style="{ width: lang === 'ru-RU' && '710px' }">
        <div class="inner_header">
          <div class="left">{{ $t('all.Message82') }}</div>
          <div class="right" @click="changeLoginType('psw')">
            <div>{{ $t('all.Message83') }}</div>
            <img :src="rightUrl" />
          </div>
        </div>

        <!-- <div class="inner_tip">{{ $t('all.Message84') }}</div> -->

        <div class="inner_body">
          <el-form ref="codeForm" class="box" :rules="rules" :model="loginForm" key="1" :label-position="'top'">
            <el-form-item prop="email" :label="$t('label.email')" v-if="$lang !== 'zh-CN'">
              <el-input :placeholder="$t('all.Message878')" v-model="loginForm.email" :autofocus="true"></el-input>
            </el-form-item>
            <el-form-item prop="phone" :label="$t('label.phoneNum')" v-else>
              <el-input :placeholder="$t('all.Message85')" v-model="loginForm.phone" :autofocus="true"><template
                  slot="prepend">+86</template></el-input>
            </el-form-item>

            <el-form-item class="code" prop="code" :label="$t('label.code')">
              <el-input :placeholder="$t('all.Message86')" v-model="loginForm.code" maxlength="6"
                @keydown.native.enter="dbCodeLogin">
              </el-input>
              <el-button size="middle" type="primary" plain :disabled="disabled" @click="getCode">{{ getCodeText }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="tip">
          <el-checkbox v-model="checked">
            {{ $t('all.Message87') }}
            <em @click.stop="goDoc('agree')">{{ $t('all.Message79') }}</em><span>{{ $t('all.Message80') }}</span>
            <em @click.stop="goDoc('privacy')">{{ $t('all.Message81') }}</em>
          </el-checkbox>
        </div>

        <div class="inner_submit">
          <el-button class="loginBtn" type="primary" size="middle" :disabled="loginDisable" @click="dbCodeLogin">{{
            $t('all.Message88') }}</el-button>
        </div>

        <div class="btns">
          <el-button size="middle" type="text" @click="register">{{ $t('all.Message89') }}</el-button>
          <el-button size="middle" type="text" @click="forgot">{{ $t('all.Message90') }}</el-button>
        </div>
        <div></div>
      </div>
      <!-- 验证码登录end ------------------------------------------------------------------------------------ -->

      <!-- 账号密码登录 ---------------------------------------------------------------------------------------->
      <div class="body" v-if="loginType == 'psw'">
        <div class="inner_header">
          <div class="left">{{ $t('all.Message83') }}</div>
          <div class="right" @click="changeLoginType('code')">
            <div>{{ $t('all.Message82') }}</div>
            <img :src="rightUrl" />
          </div>
        </div>

        <div class="inner_tip" style="height: 21px"></div>

        <div class="inner_body">
          <el-form ref="pswForm" class="box" :rules="rules" :model="loginForm" key="2" :label-position="'top'">
            <el-form-item prop="phoneEmail" :label="$t('label.phoneEmail')">
              <el-input :placeholder="$t('all.Message879')" v-model="loginForm.phoneEmail" clearable ref="autofocus">
              </el-input>
            </el-form-item>
            <el-form-item prop="password" class="passwordItem" :label="$t('label.password')">
              <el-input :placeholder="$t('all.Message91')" v-model="loginForm.password" :type="password2" maxlength="16"
                clearable @keydown.native.enter="dbPswLogin"></el-input>
              <img class="sufimg" v-if="password2" src="./assets/hide.png" alt @click="changeViewStatus2('')" />
              <img class="sufimg" v-else src="./assets/display.png" alt @click="changeViewStatus2('password')" />
            </el-form-item>
          </el-form>
        </div>

        <div class="inner_submit">
          <el-button class="loginBtn" size="middle" type="primary" @click="dbPswLogin">{{ $t('all.Message88')
            }}</el-button>
        </div>

        <div class="btns">
          <el-button size="middle" type="text" @click.stop="register">{{ $t('all.Message89') }}</el-button>
          <el-button size="middle" type="text" @click.stop="forgot">{{ $t('all.Message90') }}</el-button>
        </div>
        <div></div>
      </div>
      <!-- 账号密码登录end -------------------------------------------------------------------------------------- -->
    </div>

    <img class="right" :src="bgUrl" alt />

    <Footer />
  </div>
</template>

<script>
import md5 from "md5";
import { loginpwd, logincode, getLoginUser, checkUserAuditStatus } from "@/api/api/auth";
import { sendCaptcha, checkCaptcha } from "@/api/api/vcode";
import { getApplyList } from "@/api/api/sysUser";
import { debounce } from "@/tools/tool.js";
import {
  checkName,
  checkPassword,
  phonevalid,
  checkCode,
  phoneNumberReg,
  checkLoginPassword,
} from "@/tools/validator.js";
import Cookie from "js-cookie";
export default {
  data() {
    return {
      password2: "password",
      rightUrl: require("./assets/right.png"),
      bgUrl: require("./assets/bg@2x.png"),
      loginType: "psw",
      getCodeText: this.$t("all.Message92"),
      disabled: false,
      loginDisable: true,
      checked: false,

      options: [
        {
          langType: "zh-CN",
          langText: "中文简体",
        },
        {
          langType: "en-US",
          langText: "English",
        },
        {
          langType: "ru-RU",
          langText: "Русскийязык ",
        },
      ],
      visible: false,
      lang: Cookie.get("lang") || "en-US",

      loginForm: {
        phone: "",
        loginForm: "",
        password: "",
        phoneEmail: "",
        email: "",
        code: "",
        type: 0, // 0密码  1验证码
      },

      dbPswLogin: null,
      dbCodeLogin: null,

      rules: {
        phone: [{ required: true, validator: phonevalid, trigger: "blur" }],
        phoneEmail: [{ required: true, message: this.$t('all.Message881'), trigger: 'blur' },],
        code: [{ required: true, validator: checkCode, trigger: "blur" }],
        email: [{ required: true, message: this.$t('all.Message61'), trigger: "blur" }],
        password: [
          { required: true, validator: checkLoginPassword, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    currentLang() {
      let langText = null
      this.options.forEach(r => {
        if (r.langType === this.$lang) {
          langText = r.langText
        }
      })
      return langText
    },
  },
  watch: {
    loginType: {
      handler(n, o) {
        if (n == "code") this.loginForm.type = 1;
        if (n == "psw") this.loginForm.type = 0;
      },
      immediate: true,
    },

    "loginForm.code.length": {
      handler(n, o) {
        if (n == 6 && this.checked) this.loginDisable = false;
        else this.loginDisable = true;
      },
    },

    checked: {
      handler(n) {
        if (this.loginForm.code.length == 6 && n) this.loginDisable = false;
        else this.loginDisable = true;
      },
    },
  },
  methods: {
    goDoc(type) {
      let baseURL = this.$axios.defaults.baseURL;
      let url;
      if (type === "privacy") {
        switch (this.$lang) {
          case "en-US":
            url = baseURL + "/privacy-en-US.html";
            break;
          case "zh-CN":
            url = baseURL + "/privacy-zh-CN.html";
            break;
          case "ru-RU":
            url = baseURL + "/privacy-ru.html";
            break;
          default:
            url = baseURL + "/privacy-en-US.html";
            break;
        }
      } else {
        switch (this.$lang) {
          case "en-US":
            url = baseURL + "/agreement-en-US.html";
            break;
          case "zh-CN":
            url = baseURL + "/agreement-zh-CN.html";
            break;
          case "ru-RU":
            url = baseURL + "/agreement-ru.html";
            break;
          default:
            url = baseURL + "/agreement-en-US.html";
            break;
        }
      }
      window.open(url, "_blank");
    },
    chooseLang(option) {
      Cookie.set("lang", option.langType);
      location.reload();
      this.visible = false;
    },
    changeViewStatus2(type) {
      this.password2 = type;
    },
    phoneNumberReg,

    register() {
      this.$router.push({ name: "register" });
    },

    forgot() {
      this.$router.push({ name: "forgot" });
    },

    //登录模式切换
    changeLoginType(type) {
      this.loginType = type;
    },

    //倒计时
    counter() {
      //开始倒计时
      this.getCodeText = 60;
      this.disabled = true;

      let timer = setInterval((_) => {
        if (this.getCodeText === 0) {
          clearInterval(timer);
          this.getCodeText = this.$t("all.Message93");
          this.disabled = false;
          return;
        }
        this.getCodeText--;
      }, 1000);
    },

    //获取验证码
    getCode() {
      if (this.$lang === 'zh-CN') {
        if (!phoneNumberReg(this.loginForm.phone)) return;

      }

      let data = {
        receiver: this.$lang === 'zh-CN' ? this.loginForm.phone : this.loginForm.email,
      };

      sendCaptcha(data).then((res) => {
        this.$lang === 'zh-CN' ?
          this.$message.success(this.$t("all.Message94")) : this.$message.success(this.$t("all.Message884"))
      });

      this.counter();
    },

    //验证码登录接口调用
    axiosLoginCode() {
      sessionStorage.clear();
      localStorage.clear();

      let params = {
        account: this.$lang === 'zh-CN' ? this.loginForm.phone : this.loginForm.email,
        code: this.loginForm.code,
      };
      return logincode(params);
    },

    //密码登录接口调用
    axiosLoginPsw() {
      sessionStorage.clear();
      localStorage.clear();

      let params = {
        account: this.loginForm.phoneEmail, //这里的账号就是手机号
        // password: (this.loginForm.password),
        password: md5(this.loginForm.password),
      };
      // alert(100)
      return loginpwd(params);
    },

    //密码登录
    passwordLogin() {
      if (this.loginForm.phoneEmail == "admin") {
        return this.axiosLoginPsw().then((res) => {
          this.getUserInfo();
        });
      }

      this.$refs.pswForm.validate((v) => {
        if (!v) return;
        this.axiosLoginPsw().then((res) => {
          this.getUserInfo();
        });
      });
    },

    //验证码登录
    codeLogin() {
      this.$refs.codeForm.validate((v) => {
        if (!v) return;
        if (!this.checked) {
          this.$message.warning(this.$t('global.queryRead'))
          return
        }
        this.axiosLoginCode().then((res) => {
          this.getUserInfo(res.data);
        });
      });
    },

    //用户信息获取
    async getUserInfo() {
      // let ifAudit = true;
      // let ifConfuse = false;
      // 0：待审核 1：审核通过 2：审核拒绝

      // update:改接口判断审核状态    getApplyList改checkUserAuditStatus
      let res = await checkUserAuditStatus();
      let auditStuta = res.data
      // if (res.data===0) {
      //   ifAudit = true;
      // }
      // if (res.data===1) {
      //   ifConfuse = true;
      // }





      // if (
      //   res.data &&
      //   res.data.orgCheckStatus == 1 &&
      //   res.data.userCheckStatus == 2 &&
      //   !res.data.orgIgnore
      // ) {
      //   ifAudit = true;
      // }
      // if (
      //   res.data &&
      //   res.data.orgCheckStatus == 3 &&
      //   res.data.userCheckStatus == 2
      // ) {
      //   ifConfuse = true;
      // }

      getLoginUser().then((res) => {
        // console.log('res====', res)
        let { data: result } = res;
        this.$store.commit("SET_USERINFO", result);
        localStorage.setItem("userinfo", JSON.stringify(result));
        //============================权限有缺失的非正常用户=======================================
        // 超管
        if (result.userType == 999) {
          this.$store.commit("SET_ADMINORUSER", "root");
          this.$store.commit("ADD_ROUTES", "root");
          return
        }
        // 普通用户存在组织  直接进入
        if (result.sysOrg && result.userType == 0) {
          this.$store.commit("SET_ADMINORUSER", "user");
          this.$store.commit("ADD_ROUTES", "user");
          return
        }
        // 管理员直接进入管理员菜单
        if (result.sysOrg && result.userType == 1) {
          this.$store.commit("SET_ADMINORUSER", "admin");
          this.$store.commit("ADD_ROUTES", "admin");
          return
        }

        //没有组织游离用户 存在申请的用户
        if (!result.sysOrg) {
          // 0：待审核 1：审核通过 2：审核拒绝
          if (auditStuta === 0) {
            this.$store.commit("SET_ADMINORUSER", "audit");
            this.$store.commit("ADD_ROUTES", { info: "audit" });
          }
          if (auditStuta === 2) {
            this.$store.commit("SET_ADMINORUSER", "confuse");
            this.$store.commit("ADD_ROUTES", { info: "confuse" });
          }
        }
        // 没有组织游离用户 没有申请的用户
        if (!result.sysOrg && auditStuta === null) {
          //去创建单位页面 您当前未创建单位，请先创建单位或加入单位
          this.$store.commit("SET_ADMINORUSER", "free");
          this.$store.commit("ADD_ROUTES", "free");
        }
        // if (result.userType == 999) {
        //   this.$store.commit("SET_ADMINORUSER", "root");
        //   this.$store.commit("ADD_ROUTES", "root");
        // }

        // //超级管理员root
        // if (result.userType == 999) {
        //   this.$store.commit("SET_ADMINORUSER", "root");
        //   this.$store.commit("ADD_ROUTES", "root");
        // }
        // // update:2023-11-02   tenantId改为判断 sysOrg
        // //游离但审核失败的用户
        // else if (!result.sysOrg && auditStuta === 2) {
        //   this.$store.commit("SET_ADMINORUSER", "confuse");
        //   this.$store.commit("ADD_ROUTES", { info: "confuse" });
        // }
        // //游离但待审核的用户
        // else if (!result.sysOrg && auditStuta === 0) {
        //   this.$store.commit("SET_ADMINORUSER", "audit");
        //   this.$store.commit("ADD_ROUTES", { info: "audit" });
        //   // 
        // }
        // //游离用户
        // else if (!result.sysOrg) {
        //   this.$store.commit("SET_ADMINORUSER", "free");
        //   this.$store.commit("ADD_ROUTES", "free");
        // }
        // // //游离但审核失败的用户
        // // else if (result.tenantId == -1 && ifConfuse) {
        // //   this.$store.commit("SET_ADMINORUSER", "confuse");
        // //   this.$store.commit("ADD_ROUTES", { info: "confuse" });
        // // }
        // // //游离但待审核的用户
        // // else if (result.tenantId == -1 && ifAudit) {
        // //   this.$store.commit("SET_ADMINORUSER", "audit");
        // //   this.$store.commit("ADD_ROUTES", { info: "audit" });
        // // }
        // // //游离用户
        // // else if (result.tenantId == -1) {
        // //   this.$store.commit("SET_ADMINORUSER", "free");
        // //   this.$store.commit("ADD_ROUTES", "free");
        // // }
        // //被block的用户
        // else if (result.status == 2) {
        //   this.$store.commit("SET_ADMINORUSER", "block");
        //   this.$store.commit("ADD_ROUTES", "block");
        // }
        // //=================================正常用户==============================================
        // //管理员
        // else if (result.userType == 1) {
        //   this.$store.commit("SET_ADMINORUSER", "admin");
        //   this.$store.commit("ADD_ROUTES", "admin");
        // }
        // //普通用户
        // else if (result.userType == 0) {
        //   this.$store.commit("SET_ADMINORUSER", "user");
        //   this.$store.commit("ADD_ROUTES", "user");
        // }
      });
    },
  },
  components: {
    Header: () => import("@/components/common/header.vue"),
    Footer: () => import("@/components/common/footer.vue"),
  },

  created() {
    this.dbPswLogin = debounce(this.passwordLogin, 1500, true);
    this.dbCodeLogin = debounce(this.codeLogin, 1500, true);
  },

  mounted() {
    this.$refs.autofocus.$el.childNodes[1].focus();
  },
};
</script>

<style lang="scss" scoped>
em {
  font-style: normal;
  text-decoration: none;
  color: #298cff;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.popover_box {
  div {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;

    cursor: pointer;

    &:hover {
      background-color: rgba(41, 140, 255, 0.1);
    }
  }
}

body {
  min-width: 100vw;
}

.loginBtn {
  span {
    font-size: 1rem;
    font-weight: 500;
  }
}

.el-form {
  .el-form-item {
    padding-bottom: 1.5rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.login {
  display: flex;
  justify-content: space-between;
  // min-width: 100vw;
  height: 100%;
  position: relative;

  .left {
    width: 100%;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-left {
        padding: 0 20px;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: left;
        font-size: 18px;
        font-weight: 700;
        height: 70px;

        img {
          height: 48px;
          width: 48px;
          margin-right: 15px;
        }
      }

      .header-right {
        .currentLang {
          display: flex;
          align-items: center;

          div {
            color: #666666;
            margin: 0 4px;
            font-size: 14px;
            font-weight: 700;
          }

          i {
            color: #666666;
            margin-left: -2px;
            margin-right: 4px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .body {
      height: 570px;
      width: 600px;
      padding: 20px;
      box-sizing: border-box;
      margin: 70px auto;

      .inner_header {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        .left {
          font-size: 32px;
          font-weight: 700;
          white-space: nowrap;
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          white-space: nowrap;

          div {
            width: 100px;
            display: flex;
            justify-content: right;
          }
        }
      }

      .inner_tip {
        font-size: 16px;
        color: gray;
      }

      .inner_body {
        margin-top: 50px;

        .box {
          width: 100%;
          margin: 20px auto;

          .el-input {
            /deep/ .el-input__inner {
              height: 45px;
            }
          }

          .el-form-item.code {
            /deep/ .el-form-item__content {
              display: flex;
            }
          }

          .el-button {
            margin-left: 20px;
          }
        }
      }

      .inner_submit {
        margin-top: 40px;
        width: 100%;
        height: 45px;

        .el-button {
          width: 100%;
          height: 100%;
          color: #fff;
        }
      }

      .btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        box-sizing: border-box;

        font-size: 16px;
      }
    }
  }

  .right {
    display: block;
    height: 100%;
    width: auto;
  }

  .footer {
    display: flex;
    justify-content: left;

    position: fixed;
    height: 30px;
    bottom: 10px;
    left: 20px;
    font-size: 14px;
    color: gray;
  }

  .passwordItem {
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sufimg {
    position: relative;
    left: 10px;
    top: 4px;
  }
}
</style>
