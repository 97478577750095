<template>
  <div class="forgot">
    <div class="left">
      <Header />

      <div class="body" :style="{width:lang==='ru-RU'? '860px':'600px'}">
        <div
          class="back"
          @click="back"
        >
          <a>{{ $t('all.Message73') }}</a>
        </div>
        <el-steps
          :active="active"
          :finish-status="finish"
        >
          <el-step
            :title="$t('all.Message74')"
            description
          ></el-step>
          <el-step
            :title="$t('all.Message75')"
            description
          ></el-step>
          <el-step
            :title="$t('all.Message76')"
            description
          ></el-step>
        </el-steps>

        <div class="stepbox">
          <ResetForm
            @nextStep="nextStep"
            v-if="active == 1"
          ></ResetForm>
          <ResetPsw
            @nextStep="nextStep"
            v-if="active == 2"
          />
          <Success v-if="active == 3"></Success>
        </div>
      </div>

      <Footer />
    </div>

    <img
      class="right"
      :src="bgUrl"
    />
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  data() {
    return {
      logoUrl: require("@/assets/router/logo@2x.png"),
      bgUrl: require("./assets/bg@2x.png"),
      active: 1,
      finish: "success",
    };
  },
  computed: {
    lang() {
      return Cookie.get("lang") || "zh-CN";
    },
  },
  methods: {
    nextStep(type) {
      if (type == "phoneChecked") {
        this.active = 2;
      }
      if (type == "pswChecked") {
        this.active = 3;
      }
    },

    back() {
      this.$router.push({ name: "login" });
    },
  },

  components: {
    ResetForm: () => import("./components/resetForm.vue"),
    ResetPsw: () => import("./components/resetPassword.vue"),
    Success: () => import("./components/success"),
    Header: () => import("@/components/common/header.vue"),
    Footer: () => import("@/components/common/footer.vue")
  },
};
</script>

<style lang='scss' scoped>
body {
  min-width: 100vw;
}

a {
  text-decoration: none;
  color: #409eff;
}

.forgot {
  height: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    width: 100%;

    .body {
      height: 570px;
      // width: 600px;
      margin: 160px auto;

      .back {
        width: 100%;
        margin: 30px auto;
        transform: translateY(-200%);
        box-sizing: border-box;
        font-size: 16px;
        cursor: pointer;
      }

      .stepbox {
        width: 90%;
        margin: 50px auto;
      }
    }

    .footer {
      position: absolute;
      left: 10px;
      bottom: 10px;
      font-size: 14px;
      color: gray;
    }

    a {
      text-decoration: none;
      color: #298cff;
    }
  }

  .right {
    height: 100%;
    width: auto;
  }
}
</style>
